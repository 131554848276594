import React from 'react';

const About = () => {
    return (
        <div>
            <h1>About Page</h1>
            <p>This is the About Page where you can learn more about us!</p>
        </div>
    );
}

export default About;