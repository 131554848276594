import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import UserFetcher from './components/UserFetcher';
import LoadingState from './components/LoadingState';
import { ContextProvider } from './context/GlobalContext';
import './App.css'

function App() {
  const [loading, setLoading] = useState(true); //

  return (
    <ContextProvider>
      
      <Router>
      <UserFetcher setLoading={setLoading} />
        {loading ? (
          <LoadingState />
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
          </Routes>
        )}
      </Router>
    </ContextProvider>
  );
}

export default App;
