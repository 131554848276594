import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import './Home.css';
import AppBar from '../components/AppBar';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Context } from '../context/GlobalContext';
import ChatMessages from '../components/ChatMessages';
import ChatInput from '../components/ChatInput';
import groupThreadsByDate from '../utils/groupThreadsByDate';
import { isToday, isYesterday, differenceInCalendarDays } from 'date-fns';
import axios from 'axios';

const Home = () => {
    const { user } = useContext(Context);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [messages, setMessages] = useState([{ role: 'assistant', content: 'Здравствуйте! Чем я могу вам помочь?' }]);
    const [userInput, setUserInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [threads, setThreads] = useState({
        today: [],
        yesterday: [],
        last7Days: [],
        older: [],
    });
    const [threadId, setThreadId] = useState(null);
    const [disableInput, setDisableInput] = useState(false);
    const [newVersion, setNewVersion] = useState(false);

    const sideMenuRef = useRef(null);
    const inputRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    // Use useCallback for stable references
    const toggleMenu = useCallback(() => {
        setIsMenuOpen((prev) => !prev);
    }, []);

    const closeMenu = useCallback(() => {
        setIsMenuOpen(false);
    }, []);

    /*const handleRightButtonClick = useCallback(() => {
        alert('Right button clicked!');
    }, []);*/

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
                setIsMenuOpen(false); // Close the menu if the click is outside
            }
        };

        if (isMenuOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen && user) {
            fetchThreads();
        }
    }, [isMenuOpen, user]);

    useEffect(() => {
        const mainContent = document.querySelector('#chat-window');

        if (window.Telegram.WebApp) {
            const version = parseFloat(window.Telegram.WebApp.version);
            if (version >= 8.0) {
                setNewVersion(true);
            } else {
                console.warn('Bot API version is outdated.');
            }
        }
    }, []);

    useEffect(() => {
        const scrollContainer = document.querySelector('#chat-window');

        const handleInteraction = () => {
            if (inputRef.current) {
                inputRef.current.blur();
            }
        };

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleInteraction);
            scrollContainer.addEventListener('touchstart', handleInteraction);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleInteraction);
                scrollContainer.removeEventListener('touchstart', handleInteraction);
            }
        };
    }, [inputRef]);
/*
    useEffect(() => {
        const setViewportHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
    
        setViewportHeight(); // Set on initial load
        window.addEventListener('resize', setViewportHeight); // Update on resize
    
        return () => window.removeEventListener('resize', setViewportHeight);
    }, []);*/

    /*useEffect(() => {
        const handleFocus = () => {
            document.body.style.overflow = 'hidden';

            const controls = document.querySelector('#controls');
            if (controls) {
                controls.style.transform = 'translateY(-10px)'; // Adjust position smoothly
            }
        };
    
        const handleBlur = () => {
            document.body.style.overflow = '';

            const controls = document.querySelector('#controls');
            if (controls) {
                controls.style.transform = 'translateY(0)'; // Reset position smoothly
            }
        };
    
        const input = document.querySelector('#user-input');
        if (input) {
            input.addEventListener('focus', handleFocus);
            input.addEventListener('blur', handleBlur);
        }
    
        return () => {
            if (input) {
                input.removeEventListener('focus', handleFocus);
                input.removeEventListener('blur', handleBlur);
            }
        };
    }, []);*/

    useEffect(() => {
        const adjustLayoutForKeyboard = () => {
            const viewportHeight = window.innerHeight;
            const visualViewportHeight = window.visualViewport?.height || viewportHeight;
    
            const keyboardHeight = viewportHeight - visualViewportHeight;
    
            // Move the input field above the keyboard
            const controls = document.querySelector('#controls');
            if (controls) {
                controls.style.transform = keyboardHeight > 0 ? `translateY(-${keyboardHeight}px)` : 'translateY(0)';
            }
    
            // Ensure the chat window adjusts its height to avoid being overlapped
            const chatWindow = document.querySelector('#chat-window');
            if (chatWindow) {
                chatWindow.style.paddingBottom = keyboardHeight > 0 ? `${keyboardHeight + 60}px` : '60px';
            }
        };
    
        window.visualViewport?.addEventListener('resize', adjustLayoutForKeyboard);
        window.visualViewport?.addEventListener('scroll', adjustLayoutForKeyboard); // Handle viewport scroll caused by the keyboard
    
        return () => {
            window.visualViewport?.removeEventListener('resize', adjustLayoutForKeyboard);
            window.visualViewport?.removeEventListener('scroll', adjustLayoutForKeyboard);
        };
    }, []);

    useEffect(() => {
        const handleInputFocus = () => {
            const activeElement = document.activeElement;
            if (activeElement && activeElement.tagName === 'TEXTAREA') {
                activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        };

        const input = document.querySelector('#user-input');
        if (input) {
            input.addEventListener('focus', handleInputFocus);
        }

        return () => {
            if (input) {
                input.removeEventListener('focus', handleInputFocus);
            }
        };
    }, []);
    

    /*useEffect(() => {
        const preventAutoScroll = () => {
            window.scrollTo(0, 0); // Keep the viewport at the top
        };
    
        window.addEventListener('scroll', preventAutoScroll);
        return () => window.removeEventListener('scroll', preventAutoScroll);
    }, []);*/

    const handleThreadClick = async (thread) => {
        try {
            setThreadId(thread.thread_id);
            const response = await axios.get(`${apiUrl}/chat/get-thread-messages`, {
                params: { threadId: thread.thread_id },
            });
            setMessages(response.data);
            setIsMenuOpen(false);
        } catch (error) {
            console.error('Error fetching thread messages:', error);
        }
    };

    const fetchThreads = async () => {
        try {
            const response = await axios.get(`${apiUrl}/chat/get-threads`, {
                params: { userId: user?.userData?.id }, // Pass the userId
            });

            const uniqueThreads = response.data.filter(
                (thread, index, self) =>
                    index === self.findIndex((t) => t.thread_id === thread.thread_id)
            );

            const groupedThreads = groupThreadsByDate(uniqueThreads);

            setThreads(groupedThreads); // Update the threads state with unique threads
            console.log('Fetched threads:', groupedThreads);
        } catch (error) {
            console.error('Error fetching threads:', error);
        }
    };

    const handleSendMessage = async () => {
        console.log('handleSendMessage, threadId:', threadId);
        if (!userInput.trim()) return;

        const newMessage = { role: 'user', content: userInput };
        console.log(newMessage);
        setMessages((prev) => [...prev, newMessage]); // Add user message to chat
        setUserInput(''); // Clear input field
        setDisableInput(true);

        try {
            setIsLoading(true);
            console.log('Sending message:', { threadId, userId: user?.id, assistantId: 'asst_Jr5vdoh0Kc3YtIgXGaJjZ9r8', message: userInput });
            const response = await fetch(`${apiUrl}/chat/send-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    threadId,
                    userId: user?.id,
                    assistantId: 'asst_Jr5vdoh0Kc3YtIgXGaJjZ9r8',
                    message: userInput,
                }),
            });
            response.headers.forEach((value, name) => console.log(`${name}: ${value}`));

            if (!response.body) {
                throw new Error('No response body');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let assistantMessage = { role: 'assistant', content: '' };
            let done = false;

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                setIsLoading(false);
                done = doneReading;
                const chunk = decoder.decode(value, { stream: !done });

                console.log('Chunk received:', chunk);

                if (chunk) {
                    assistantMessage.content += chunk; // Trim and add a space between chunks
                }
                setMessages((prev) => {
                    const updated = [...prev];
                    if (updated[updated.length - 1]?.role === 'assistant') {
                        updated[updated.length - 1] = {
                            ...updated[updated.length - 1],
                            content: updated[updated.length - 1].content + chunk,
                        };
                    } else {
                        updated.push({ ...assistantMessage });
                    }
                    return updated;
                });
            }

            const threadIdFromHeader = response.headers.get('X-Thread-Id');
            console.log('threadIdFromHeader', threadIdFromHeader);

            if (threadIdFromHeader) {
                setThreadId(threadIdFromHeader); // Update threadId in the state

                // Add the new thread to the threads list
                setThreads((prevThreads) => {
                    const newThread = { thread_id: threadIdFromHeader, created: new Date().toISOString() };

                    const updatedThreads = { ...prevThreads };

                    // Determine which group the new thread belongs to
                    if (isToday(new Date(newThread.created))) {
                        const exists = updatedThreads.today.some((t) => t.thread_id === newThread.thread_id);
                        if (!exists) updatedThreads.today = [...updatedThreads.today, newThread];
                    } else if (isYesterday(new Date(newThread.created))) {
                        const exists = updatedThreads.yesterday.some((t) => t.thread_id === newThread.thread_id);
                        if (!exists) updatedThreads.yesterday = [...updatedThreads.yesterday, newThread];
                    } else if (differenceInCalendarDays(new Date(), new Date(newThread.created)) <= 7) {
                        const exists = updatedThreads.last7Days.some((t) => t.thread_id === newThread.thread_id);
                        if (!exists) updatedThreads.last7Days = [...updatedThreads.last7Days, newThread];
                    } else {
                        const exists = updatedThreads.older.some((t) => t.thread_id === newThread.thread_id);
                        if (!exists) updatedThreads.older = [...updatedThreads.older, newThread];
                    }

                    return updatedThreads;
                });

                console.log('Received threadId:', threadIdFromHeader);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send the message. Please try again.');
        } finally {
            setDisableInput(false); // Re-enable input after the response
        }
    };

    return (
        <div id="chat-window">
            {/*<AppBar onBurgerClick={toggleMenu} onRightButtonClick={handleRightButtonClick} />*/}

            {/*<div id="active-thread">
                <strong>Active Thread ID:</strong> {threadId || 'No active thread'}
            </div>*/}

            {isMenuOpen && <div className="overlay" onClick={closeMenu}></div>}

            <div id="side-menu" ref={sideMenuRef} className={isMenuOpen ? 'open' : ''}>
                <button className="close-button" onClick={closeMenu}>
                    <CloseRoundedIcon />
                </button>
                <div id="side-menu-container">

                    {threads.today.length > 0 && (
                        <>
                            <div className='day-separator-header'>Сегодня</div>
                            <ul>
                                {threads.today.map((thread) => (
                                    <li key={thread.thread_id} onClick={() => handleThreadClick(thread)}>
                                        <div className='thread-name'>{thread.thread_name}</div>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {threads.yesterday.length > 0 && (
                        <>
                            <div className='day-separator-header'>Вчера</div>
                            <ul>
                                {threads.yesterday.map((thread) => (
                                    <li key={thread.thread_id} onClick={() => handleThreadClick(thread)}>
                                        <div className='thread-name'>{thread.thread_name}</div>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {threads.last7Days.length > 0 && (
                        <>
                            <div className='day-separator-header'>7 дней</div>
                            <ul>
                                {threads.last7Days.map((thread) => (
                                    <li key={thread.thread_id} onClick={() => handleThreadClick(thread)}>
                                        <div className='thread-name'>{thread.thread_name}</div>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {threads.older.length > 0 && (
                        <>
                            <div className='day-separator-header'>Ранее</div>
                            <ul>
                                {threads.older.map((thread) => (
                                    <li key={thread.thread_id} onClick={() => handleThreadClick(thread)}>
                                        <div className='thread-name'>{thread.thread_name}</div>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>

            </div>

            <ChatMessages messages={messages} isLoading={isLoading} />
            <ChatInput
                userInput={userInput}
                setUserInput={setUserInput}
                isLoading={isLoading}
                handleSendMessage={handleSendMessage}
                disableInput={disableInput}
                onBurgerClick={toggleMenu}
                inputRef={inputRef}
            />
        </div>
    );
};

export default Home;