import { parseISO, isToday, isYesterday, differenceInCalendarDays } from 'date-fns';

const groupThreadsByDate = (threads) => {
    const today = [];
    const yesterday = [];
    const last7Days = [];
    const older = [];

    threads.forEach((thread) => {
        if (!thread.created) {
            // Skip invalid or missing dates
            console.warn(`Thread with ID ${thread.thread_id} has an invalid created date.`);
            older.push(thread); // Fallback for invalid dates
            return;
        }

        const createdDate = parseISO(thread.created); // Parse ISO 8601 date
        if (isNaN(createdDate)) {
            console.warn(`Unable to parse date for thread ID ${thread.thread_id}: ${thread.created}`);
            older.push(thread); // Fallback for unparseable dates
            return;
        }

        if (isToday(createdDate)) {
            today.push(thread);
        } else if (isYesterday(createdDate)) {
            yesterday.push(thread);
        } else {
            const daysAgo = differenceInCalendarDays(new Date(), createdDate);
            if (daysAgo <= 7) {
                last7Days.push(thread);
            } else {
                older.push(thread);
            }
        }
    });

    return { today, yesterday, last7Days, older };
};

export default groupThreadsByDate;
