import { useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/GlobalContext';
import { getLocalUserData, verifyTgData } from '../utils/api';

const UserFetcher = ({ setLoading }) => {
    const { user, setUser } = useContext(Context);
    const navigate = useNavigate();
    const hasFetched = useRef(false);

    useEffect(() => {
        const environment = process.env.REACT_APP_NODE_ENV;
        console.log('Environment: ', environment);

        const tg = window.Telegram.WebApp;
        tg.ready();
        tg.expand();
        tg.disableVerticalSwipes();

        if (parseFloat(tg.version) >= 8.0) {
            tg.requestFullscreen();
        } else {
        console.log("Bot API version is outdated. Avoid using unsupported methods.");
        }


        const fetchUser = async () => {
            setLoading(true);
            const testUserId = '65fabd22-45fd-47d1-af8c-92a407937707';

            try {
                if (environment === 'local') {
                    const userData = await getLocalUserData(testUserId);
                    console.log('User Data:', userData);
                    setUser(userData);
                } else if (environment === 'development') {
                    const userData = await verifyTgData(tg.initData);
                    console.log('User Data:', userData);
                    setUser(userData);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (!user && !hasFetched.current) {
            hasFetched.current = true; // Mark as fetched
            fetchUser();
        } else {
            navigate('/');
        }
    }, [user, setUser, navigate, setLoading]);

    return null;
};

export default UserFetcher;
