import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL || '';

export const verifyTgData = async (initData) => {

    const controller = new AbortController();
    const timeout = setTimeout(() => {
        controller.abort(); // Aborts the request after the timeout
    }, 10000); // Set a 10-second timeout for the request

    try {
        const response = await fetch(`${apiUrl}/users/verify-telegram-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ initData }),
            signal: controller.signal, // Attach the abort controller to the request
        });

        clearTimeout(timeout); // Clear the timeout once the request completes

        if (!response.ok) {
            // Handle HTTP errors (e.g., 404, 500)
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Verified Telegram data: ', data);
        return data;
        
    } catch (error) {
        if (error.name === 'AbortError') {
            console.error('Request timed out');
        } else {
            console.error('Error verifying Telegram data:', error.message || error);
        }

        // Optionally return a default response or handle the error as needed
        return { success: false, message: error.message || 'Failed to verify Telegram data' };
    }
};

export const getLocalUserData = async (testUserId) => {
    try {
        const response = await axios.get(`${apiUrl}/users/getUser`, {
            params: { userId: testUserId },
        });

        console.log('getLocalUserData response: ', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching user data:', error.response ? error.response.data : error.message);
        throw error;
    }
};