import React, { createContext, useState } from 'react';

// Create a context
export const Context = createContext();

// Provider component
export const ContextProvider = ({ children }) => {
    // State for projects
    const [user, setUser] = useState(null);
    const [webApp, setWebApp] = useState(null);
    

    return (
        <Context.Provider
            value={{
                user, setUser,
                webApp, setWebApp,
            }}
        >
            {children}
        </Context.Provider>
    );
};
