import React from 'react';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';

const ChatInput = React.memo(({ userInput, setUserInput, isLoading, handleSendMessage, disableInput, onBurgerClick, inputRef }) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent adding a new line
            handleSendMessage(); // Trigger send message
        }
    };
    return (
        <div id="controls">
            <button id="burger-button" onClick={onBurgerClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z" fill="currentColor"></path></svg>
            </button>
            <div id='input-container'>
                <textarea
                    id="user-input"
                    placeholder="Ваше сообщение"
                    inputMode="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    disabled={disableInput}
                    ref={inputRef}
                />
                <button id="send-button" onClick={handleSendMessage} disabled={isLoading}>
                    <ArrowUpwardRoundedIcon />
                </button>
            </div>
        </div>
    );
});

export default ChatInput;